import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import Band from "../../../components/band"
import FlowDataTableTopNav from "../../../components/top-nav/flow-top-nav"
import SEO from "../../../components/seo"
import cardStyles from "../../../shared/card.module.css"
import grid from "../../../shared/bootstrap-grid.module.css"

const FlowDataTableSetupPage = () => (
  <Layout>
    <SEO title="Flow Data Table" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `flow data table`]} />

    <FlowDataTableTopNav />

    <Band>
      <div className={grid.row}>
        <div className={grid.colLg6}>
          <Link to="/products/flow-data-table/architectural-overview" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Architectural Overview</h3>
            <p style={{ flexGrow: '1' }}>
              An architectural overview of the Flow Data Table product.
            </p>
            <small>15 mins</small>
          </Link>
        </div>
        <div className={`${grid.colLg6} ${grid.mt4} ${grid.mtLg0}`}>
          <Link to="/products/flow-data-table/user-guides/getting-started" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Getting Started</h3>
            <p style={{ flexGrow: '1' }}>
              Setup your org and build a basic flow with Flow Data Table & Flow Builder.
            </p>
            <small>10 mins</small>
          </Link>
        </div>
        <div className={`${grid.colLg6} ${grid.mt4}`}>
          <Link to="/products/flow-data-table/user-guides/configuration" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Configuration</h3>
            <p style={{ flexGrow: '1' }}>
              View all the configuration options Flow Data Table offers.
            </p>
            <small>3 mins</small>
          </Link>
        </div>
        <div className={`${grid.colLg6} ${grid.mt4}`}>
          <Link to="/products/flow-data-table/user-guides/output-selected-records" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Outputting Selected Records</h3>
            <p style={{ flexGrow: '1' }}>
              Output the list of selected records from the data table to subsequent steps of the flow.
            </p>
            <small>8 mins</small>
          </Link>
        </div>
        <div className={`${grid.colLg6} ${grid.mt4}`}>
          <Link to="/products/flow-data-table/user-guides/pre-selecting-records" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Pre Selecting Records</h3>
            <p style={{ flexGrow: '1' }}>
              Display a list of records in the data table with some records already selected.
            </p>
            <small>8 mins</small>
          </Link>
        </div>
        <div className={`${grid.colLg6} ${grid.mt4}`}>
          <Link to="/products/flow-data-table/user-guides/edit-in-memory" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Edit Records In Memory</h3>
            <p style={{ flexGrow: '1' }}>
              Output edited records from the data table to subsequent steps of the flow.
            </p>
            <small>8 mins</small>
          </Link>
        </div>
        <div className={`${grid.colLg6} ${grid.mt4}`}>
          <Link to="/products/flow-data-table/user-guides/edit-real-time" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Edit Records Real Time</h3>
            <p style={{ flexGrow: '1' }}>
              Edit records directly in your orgs database in real time from a flow.
            </p>
            <small>8 mins</small>
          </Link>
        </div>
        <div className={`${grid.colLg6} ${grid.mt4}`}>
          <Link to="/products/flow-data-table/user-guides/record-types" className={`${cardStyles.card} ${cardStyles.cardHover} ${cardStyles.cardLink}`}>
            <h3>Record Types</h3>
            <p style={{ flexGrow: '1' }}>
              Edit Picklist Values Configured for Record Types.
            </p>
            <small>12 mins</small>
          </Link>
        </div>
      </div>
    </Band>
  </Layout>
);

export default FlowDataTableSetupPage;