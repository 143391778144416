import React from "react"
import { Link } from "gatsby"

import styles from "./flow-top-nav.module.css"
import sharedStyles from "../../shared/shared.module.css"

const FlowDataTableTopNav = () => (
  <div className={styles.topNav}>
    <div className={styles.topNavContent}>
      <h3 className={styles.topNavTitle}>Flow Data Table</h3>
      <Link to={'/products/flow-data-table/summary'} className={sharedStyles.tigerfaceLinkLight}>
        Summary
      </Link>
      <Link to={'/products/flow-data-table/features'} className={sharedStyles.tigerfaceLinkLight}>
        Features <span className={sharedStyles.hiddenMobile}>& Pricing</span>
      </Link>
      <Link to={'/products/flow-data-table/setup'} className={sharedStyles.tigerfaceLinkLight}>
      <span className={sharedStyles.hiddenMobile}>User </span>Guide
      </Link>
      <Link to={'/products/flow-data-table/faq'} className={sharedStyles.tigerfaceLinkLight}>
        FAQ
      </Link>
    </div>
  </div>
);

export default FlowDataTableTopNav;